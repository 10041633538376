<template>
  <div class="block-select-list">
    <BlockSelectItem v-for="(item, index) in list" :key="index" :list="item" :labels="labels" :active="activeMap[item[activeField]]" @click.native="handleClick(index)" />
  </div>
</template>

<script>
import BlockSelectItem from './blockSelectItem.vue'

export default {
  components: {
    BlockSelectItem
  },
  props: {
    init: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    // 接口获取的直接数据
    list: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    // 配置label和字段名
    labels: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    // 激活的字段 
    activeField: {
      type: String,
      value: ''
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeMap: {},
    }    
  },
  beforeMount() {
    this.init.map(item=>{
      this.activeMap[item[this.activeField]] = true
    })
  },
  activated(){
    this.activeMap = {};
  },
  methods: {
    getAllActive() {
      let ret = []
      this.list.map(val=>{
        if(val[this.activeField] in this.activeMap) {
          ret.push(val)
        }
      })
      return ret
    },

    handleClick(index) {
      let clickItem = this.list[index][this.activeField]
      if (clickItem in this.activeMap){
        this.$delete(this.activeMap, clickItem)
      }
      else {
        if (this.single) {
          this.activeMap = {}
        }
        this.$set(this.activeMap, clickItem, true)
      }
      this.$emit('change', this.getAllActive())
    }
  }
}
</script>

<style lang="scss" scoped>
.block-select-list {
  width: 100%;
  // min-height: 100vh;
  padding-top: 12px;
}
</style>