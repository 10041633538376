import { commonApi } from "@/utils/api.js";

const nameMaps = {};
// 根据类型获取 id => name
export async function fetchNameById(key, id) {
  if (!nameMaps[key]) {
    const response = await commonApi.dictList({
      type: key,
    });
    setNameMap(key, response.data || []);
  }
  return nameMaps[key][id] || "";
}
// 存储id => name 的映射关系
export function setNameMap(
  key,
  arr,
  labelString = "dictLabel",
  valueString = "dictValue"
) {
  const obj = arr.reduce((prev, current) => {
    prev[current[valueString]] = current[labelString];
    return prev;
  }, {});
  nameMaps[key] = { ...(nameMaps[key] || {}), ...obj };
}
