<template>
  <div class="salesman-select-page">
    <Header :text="selectType !== 'receive' ? '确定' : [{text: '新增地址', event: 'addNew'}, {text: '确定', event: 'textClick'}]" @textClick="$emit('confirm')" @addNew="handleAddNew" />
    <div class="search-container">
      <Search 
        v-model="searchTotal"
        @search="resetList"
      />
    </div>
    <div class="select-list-container">
      <BlockSelectList :single="single" :init="value" :list="list" :activeField="activeField[selectType]" :labels="labels[selectType]" @change="handleChange" />
    </div>
    <!-- 无限加载 -->
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithText/index.vue'
import BlockSelectList from './components/blockSelectList.vue'
import Search from '@/components/common/search/index.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchSalesmanList, fetchSDLTerminalList, fetchZYTerminalList, fetchSDLGrowerList, fetchZYGrowerList, fetchDealerList, fetchReceiveAddressList, fetchSetList, fetchSDLModelFieldList, fetchZYModelFieldList, fetchBrandList } from '@/api/select'
import * as util from "@/utils/util.js";

export default {
  props: {
    value: {},
    selectType: {
      type: String,
      default: 'terminal'
    },
    // params: shidanli   ziyou
    userType: {
      type: String,
      default: 'shidanli'
    },
    single: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    directReturn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BlockSelectList,
    Header,
    Search,
    InfiniteLoading
  },
  data() {
    return {
      // 传给blocklist的参数必穿 activeField labels list
      // activeField: String, labels: [{label: String, field: String}] list: Array
      activeField: {
        terminal: 'id',  // 查询问题
        salesman: 'userId', // ok
        grower: 'id', // ok
        modelField: 'id', // ok
        brand: 'dictValue', // ok
        dealer: 'id',  // ok
        receive: 'rowId',
        set: 'rowId'
      },
      labels: {
        terminal: [
          {label: '联系人', field: 'contactPerson'},
          {label: '所属经销商名称', field: 'dealerName'},
        ],
        salesman: [
          {label: '姓名', field: 'nickName'},
          {label: '性别', field: 'sexName'},
          {label: '手机号', field: 'phonenumber'},
          {label: '经销商编号', field: 'dealerId'},
        ],
        grower: [
          {label: '种植大户名称', field: 'bigfarmersPerson'},
        ],
        modelField: [
          {label: '示范田名称', field: 'modelFieldName'},
        ],
        brand: [
          {label: '', field: 'dictValue'},
        ],
        dealer: [
          {label: '经销商姓名', field: 'dealerName'},
          {label: '经销商编码', field: 'dealerId'}
        ],
        receive: [
          {label: '收货单位名称', field: 'tranAddress'},
          {label: '收货人', field: 'tranConsignee'},
          {label: '手机号', field: 'tranTel'},
          {label: '到局名称', field: 'tranAdvent'},
          {label: '到站名称', field: 'tranArrival'},
          {label: '收货地址', field: 'tranAddress'},
          {label: '小票邮寄地址', field: 'tranBillAddress'},
        ],
        set: [
          {label: '品牌名称', field: 'brand'},
          {label: '备注', field: 'remark'},
        ]
      },
      list: [],
      pageNo: 1,
      pageSize: 20,
      total: 0,
      searchTotal: '',
    }
  },
  activated() {
    
  },
  methods: {
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        switch(this.selectType){
          case 'terminal': {
            await this.fetchTerminalList();
            break
          }
          case 'salesman': {
            await this.fetchSalesmanList();
            break
          }
          case 'grower': {
            await this.fetchGrowerList()
            break
          }
          case 'modelField': {
            await this.fetchModelFieldList()
            break
          }
          case 'brand': {
            await this.fetchBrandList()
            break
          }
          case 'dealer': {
            await this.fetchDealerInfoList()
            break
          }
          case 'receive': {
            await this.fetchReceiveList()
            break
          }
          case 'set': {
            await this.fetchSetList()
            break
          }
          default: {
            await this.fetchSalesmanList();
            break
          }
        }
        $state.loaded();
        this.pageNo += 1;
        if (this.total - this.list.length === 0) {
          $state.complete();
        }
        this.setNameMap();
      } catch (e) {
        $state.loaded();
        $state.complete();
      }
    },

    async fetchSalesmanList() {
      let response = []
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "searchTotal": this.searchTotal
      }
      response = await fetchSalesmanList(data)
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0
    },

    async fetchTerminalList() {
      let response = []
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "coordinate": "",
        "page.orderBy": 'distance',
        "searchTotal": this.searchTotal
      }
      if (this.userType === 'shidanli') {
        response = await fetchSDLTerminalList(data)
      }
      else if(this.userType === 'ziyou') {
        response = await fetchZYTerminalList(data)
      }
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0
    },

    async fetchGrowerList() {
      let response = {}
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "searchTotal": this.searchTotal
      }
      if (this.userType === 'shidanli') {
        response = await fetchSDLGrowerList(data)
      }
      else if(this.userType === 'ziyou') {
        response = await fetchZYGrowerList(data)
      }
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0 
    },

    async fetchModelFieldList() {
      let response = {}
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "searchTotal": this.searchTotal,
        'page.orderBy': "distance"
      }
      if (this.userType === 'shidanli') {
        response = await fetchSDLModelFieldList(data)
      }
      else if(this.userType === 'ziyou') {
        response = await fetchZYModelFieldList(data)
      }
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0 
    },

    async fetchBrandList() {
      const response = await fetchBrandList();
      this.list = this.list.concat(response.data || []);
      this.total = response.data.length;
    },

    async fetchDealerInfoList() {
      let response = {}
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "searchTotal": this.searchTotal
      }
      response = await fetchDealerList(data)
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0 
    },

    async fetchReceiveList() {
      let response = {}
      let data = {
        ...this.params,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "searchTotal": this.searchTotal
      }
      response = await fetchReceiveAddressList(data)
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0
    },
    async fetchSetList() {
      let data = {
        ...this.params,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "searchTotal": this.searchTotal
      }
      let response = await fetchSetList(data)
      this.list = this.list.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0
    },
    handleChange(data) {
      this.$emit('input', data)
      if (this.single && this.directReturn) {
        this.textClick()
      }
    },

    setNameMap() {
      switch (this.selectType) {
        case "grower": {
          util.setNameMap(this.selectType, this.list, 'bigfarmersPerson', 'id');
          break;
        }
        case "terminal": {
          util.setNameMap(this.selectType, this.list, 'contactPerson', 'id');
          break;
        }
      }
    },

    handleAddNew() {
      this.$router.push({path: '/address/boat', query: {tranMode: this.params.tranMode}})
    }
  },
}
</script>

<style lang="scss" scoped>
.salesman-select-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  background: #FAFBFC;
  .search-container {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 10px 24px 10px 12px;
    background: white;
  }
  .select-list-container {
  }

}
</style>
