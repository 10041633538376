<template>
  <div class="block-select-item" :class="{'active': active, 'not-active': !active}">
    <div class="label-value-wrap" v-for="(item, index) in computedLabels" :key="index">
      <div class="label" >{{item.label}}</div>
      <div class="value" :style="{color: active ? 'white' : ''}">{{list[item.field]}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  computed: {
    computedLabels() {
      return this.labels.filter(item=>{
        return !!this.list[item.field]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.block-select-item {
  border-bottom: 1px solid #fafbfc;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 24px 15px;
  font-size: 14px;
  .label-value-wrap {
    width: 100%;
    min-height: 20px;
    display: flex;
    align-items: center;
    text-align: left;
    .label {
      white-space: nowrap;
    }
    .value {
      margin-left: 9px;
    }
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
.active {
  color: #FFFFFF;
  background: #CD001F;
}
.not-active {
  background: white;
  .label {
    color: #818181;
  }
  .value {
    color: #2C2C2C;
  }
}
</style>